import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";


  export default {
    fillable_by:FillableBy.Both,
    title:
      "Hyperthermic Intraperitoneal Chemotherapy Clinical Competency Form",
    sections: [
      {
        title: "Competency Statement: During orientation, and on a annual basis thereafter, the employee's clincal competencies shall be assessed.",
        type: "table",
        heads: constants.tableHeads,
        rows :[
            "Review of HIPEC Policy",
            "Belmont Machine / Physiologic monitoring / Disposable equipment",
            "HIPEC Patient Charting",
            "Prepare all equipment for use according to department policy and manufacturer’s specifications",
            "Disposal of cytotoxic drugs post procedure",
            "OR Preparation for Cytoreductive Surgery - Including spill kits and proper PPE",
            "Completes/ Maintains Perfusion Records/ Database",
            "Review of Belmont Hyperthermic Unit IFUs",
        ].map((item) => ([{ text: item, type: 'text' }, ...table_fields]))
      },
      {...evaluationCheckList},
      {
        title: "Signatures",
        type: "signature",
        fields:getSignatures(FillableBy.Both)
      },
    ]
  }